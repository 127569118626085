import {Container, Box, Button, Image, Group, Text, Center, Title, Anchor, SimpleGrid, Grid, ThemeIcon, Stack} from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { Link } from "react-router-dom";
import classes from './Homepage.module.css';
import { FullStory } from '@fullstory/browser';
import shellClasses from './AppShell.module.css';

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-11068614790/PZmfCODt78oZEIbR9p0p',
      'event_callback': callback
  });
  return false;
}
 
function Homepage() {
  const benefits = [
    {
      imageUrl: 'https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/clock_bolt.webp',
      title: 'For busy people',
      description: 'Bite sized content on the go',
    },
    //{
    //  imageUrl: 'https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/puzzle_pieces.webp',
    //  title: 'All skills',
    //  description: 'Read and write, LISTEN and SPEAK, words and GRAMMAR',
    //},
    {
      imageUrl: 'https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/puzzle_pieces.webp',
      title: 'Core skills',
      description: 'LISTEN and read, learn words and GRAMMAR',
    },
    {
      imageUrl: 'https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/mountain_peak.webp',
      title: 'Know actual Spanish',
      description: 'No textbook language or random sentences',
    }];

  const features = [
    {
      title: 'Easy to follow',
      description: 'Short sentences, bite sized content',
    },
    {
      title: 'Easy to understand',
      description: 'Context images',
    },
    {
      title: 'Easy to learn',
      description: 'Full explanation for words and grammar',
    },
    {
      title: 'Easy to hear',
      description: 'Audio by native voice actors',
    }];
    
  const heroSection = 
    <Container size="100%" bg='#3472ed' py={20}>
      <Group justify="center" gap={40}>
        <div mr="lg">

          <Title className={classes.title} ta="center">
            Learn Spanish with Real Content from Day 1
          </Title>

          <Text mt="md" size="xl" c='white' ta="center">
            Our content is crafted specifically for beginners: bite-sized, easy to follow, and fully explained. Real content keeps you engaged from day one.
          </Text>


          <Center mt={30}>
            <Link to="item/1/1">
              <Button radius="xl" size="md" color="orange" onClick={() => { gtag_report_conversion(); FullStory('trackEvent', { name: 'cta_click', properties: { entity: 'hero_section' } }); } }>
                See The Content
              </Button>
            </Link>
          </Center>
          {
            /*<Center mt="sm">
              <Text c='white' size="sm">
                (No credit card required)
              </Text>
            </Center>*/
          }
        </div>
        <Image src='https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/hero.webp' h={300} w="auto" fit="contain" />

      </Group>
    </Container>;

  const benefitsSection = 
    <Container py={20}>
      <SimpleGrid cols={{ base: 1, xs: 3 }} spacing={40}>
        {benefits.map((item) => (
          <div key={item.image}>
            <Center>
              <ThemeIcon variant="white" size={70}>
                <Image src={item.imageUrl} />
              </ThemeIcon>
            </Center>
            <div>
              <Text fw={700} fz="lg" align="center" pt="sm" >
                {item.title}
              </Text>
              <Text align="center">{item.description}</Text>
            </div>
          </div>
        ))}
      </SimpleGrid>
    </Container>

  const featuresSection = 
    <Box py={20} bg='indigo.0'>
      <Container size="xs">

        <Title order={2} ta="center" mb={20}>
          Content tailored to beginners
        </Title>

        {features.map((item) => (
          <div key={item.image}>
            <Grid align="center">
              <Grid.Col span="content">
                <IconCircleCheckFilled size={60} color="green"/>
              </Grid.Col>
              <Grid.Col span="auto">
                <div>
                  <Text fw={700}>
                    {item.title}
                  </Text>
                  <Text>{item.description}</Text>
                </div>
              </Grid.Col>
            </Grid>
          </div>
        ))}
      </Container>
    </Box>

  const bottomCtaSection = 
    <Container py={20}>
      <Stack>
        <Center>
          Try it out - no credit card is needed
        </Center>
        <Center>
            <Link to="item/1/1">
              <Button radius="xl" size="md" color="orange" onClick={() => { gtag_report_conversion(); FullStory('trackEvent', { name: 'cta_click', properties: { entity: 'hero_section' } }); } }>
                See The Content
              </Button>
            </Link>
        </Center>
      </Stack>
    </Container>

  const Footer = 
    <Group p="md" className={shellClasses.footer} c="dimmed" style={{ justifyContent: 'space-between' }}>
      <Text>© 2024 Levelang. All rights reserved.</Text>
      <Group>
        <Anchor
          component={Link}
          to="privacy"
          c="dimmed"
          key="privacy"
        >
          Privacy
        </Anchor>

        <Anchor
          component={Link}
          to="tos"
          c="dimmed"
          key="tos"
        >
          TOS
        </Anchor>

      </Group>
    </Group>

  return (
    <>
      {heroSection}
      {benefitsSection}
      {featuresSection}
      {bottomCtaSection}
      {Footer}
    </>
  );
}


export default Homepage;
