import './App.css';
import { useState, useEffect } from "react";
import { AppShell, Burger, Group, Image, Text, Popover, ActionIcon, Box, Title } from '@mantine/core';
import { useDisclosure, useFavicon } from '@mantine/hooks';
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import shellClasses from './AppShell.module.css';
import Homepage from './Homepage.js';
import NotFoundPage from './NotFoundPage.js';
import TosPage from './TosPage.js';
import UpdatesPage from './UpdatesPage.js';
import AboutPage from './AboutPage.js';
import PrivacyPage from './PrivacyPage.js';
import SessionPage from './SessionPage.js';
import languageElements from './languageElements.json';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  const [opened, { toggle }] = useDisclosure(false);
  const headerHeight = 55;
  useFavicon('favicon.ico');

  const [showProgress, setShowProgress] = useState(false);
  const [learnedWords, setLearnedWords] = useState([]);
  const [learnedGrammars, setLearnedGrammars] = useState([]);
  
  const saveProgress = (learnedElementIds) => {
    const newLearnedWords = [];
    const newLearnedGrammars = [];

    if(learnedElementIds.length > 0) {
      setShowProgress(true);
    }
  
    learnedElementIds.forEach(elementId => {
      if (languageElements[elementId]['type'] === 'word' && !learnedWords.includes(elementId) && !newLearnedWords.includes(elementId)) {
        newLearnedWords.push(elementId);
      }
      if (languageElements[elementId]['type'] === 'grammar' && !learnedGrammars.includes(elementId) && !newLearnedGrammars.includes(elementId)) {
        newLearnedGrammars.push(elementId);
      }
    });
  
    if (newLearnedWords.length > 0) {
      setLearnedWords([...learnedWords, ...newLearnedWords]);
    }
  
    if (newLearnedGrammars.length > 0) {
      setLearnedGrammars([...learnedGrammars, ...newLearnedGrammars]);
    }
  };
  

  return (
    <BrowserRouter>
      <AppShell
        header={{ height: headerHeight}}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
      >
        <AppShell.Header style={{ backgroundColor: '#3472ed' }}>
          <Group h="100%" px="md" justify="space-between" style={{ flex: 1 }}>
            <Group>
              {!showProgress && <Link to="" className={shellClasses.logo_link}><Image src='https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/logo.webp' h={headerHeight-10} w="auto"/></Link>}
              <Link to="" className={shellClasses.logo_link}><Text size="xl" style={{color: 'white', fontWeight: 'bold'}}>Levelang</Text></Link>
            </Group>

            <Group>
              {showProgress &&
                <Popover position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <Box bg="rgba(148, 169, 255, 1)" h="45" px="sm" style={{display: 'flex', borderRadius: '10px', align: "center"}}>
                      <Group>
                        <ActionIcon variant="transparent" size="xl"><Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/words_diamond.webp" /></ActionIcon>
                        <Text fw={700} c="white">{learnedWords.length}</Text>
                        <ActionIcon variant="transparent" size="xl"><Image height="100%" width="auto" src="https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/grammars_diamond.webp"/></ActionIcon>
                        <Text fw={700} c="white">{learnedGrammars.length}</Text>
                      </Group>
                    </Box>
                  </Popover.Target>
                  <Popover.Dropdown style={{ maxHeight: 250, overflowY: 'auto' }}>
                    <Title order={4}>Your learned words and grammar:</Title>
                    {learnedWords.map((elementId, index) => (
                              <Text key={index}>{languageElements[elementId]['name']}</Text>
                                    ))}
                    {learnedGrammars.map((elementId, index) => (
                              <Text key={index}>{languageElements[elementId]['name']} (grammar)</Text>
                                    ))}
                    
                  </Popover.Dropdown>
                </Popover>
              }
              <Group gap={0} visibleFrom="sm">
                <Link to="" className={shellClasses.header_link}>Home</Link>
                <Link to="about" className={shellClasses.header_link}>About</Link>
              </Group>
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" className={shellClasses.burger} color="white"/>
            </Group>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar py="md" px={4} style={{ backgroundColor: '#3472ed' }}>
          <Link to="" className={shellClasses.header_link} onClick={() => toggle()}>Home</Link>
          <Link to="about" className={shellClasses.header_link} onClick={() => toggle()}>About</Link>
        </AppShell.Navbar>

        <AppShell.Main className={shellClasses.main}>
          <ScrollToTop />
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="updates" element={<UpdatesPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="tos" element={<TosPage />} />
            <Route path="item/:itemId" element={<SessionPage saveProgress={saveProgress} />} />=
            <Route path="item/:itemId/:pageId" element={<SessionPage saveProgress={saveProgress} />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>


        </AppShell.Main>
      </AppShell>
    </BrowserRouter>
  );
}

export default App;
